/*
 -  Theme variables
 -
 -  This file defines new variables and extends standards variables from Bootstrap.
 */

// Try to organize variables in named block groups with certain concern

// Colors
@import '01_variables/colors';

// Grid
@import '01_variables/grid';

// Icons
@import '01_variables/icons';

// Typography
@import '01_variables/typography';

// Footer
@import '01_variables/footer';

// Accordion
@import '01_variables/accordion';

// Accordion
@import '01_variables/breadcrumb';

// Liferay
@import '01_variables/liferay';

// General styling
@import '01_variables/general';

// Header
@import '01_variables/header';

// News
@import '01_variables/forms';

// News
@import '01_variables/news';

// Navigation tile
@import '01_variables/navigation-tile';

// Tables
@import '01_variables/tables';

// Section
@import '01_variables/section';

// Separator
@import '01_variables/separator';

// Buttons
@import '01_variables/buttons';

// Navigation vertical
@import '01_variables/navigation-vertical';

// Navigation
@import '01_variables/navigation';

// Aside navigation
@import '01_variables/aside-navigation';

// Banner
@import '01_variables/banner';

// Carousel
@import '01_variables/carousel';

// Slick Slider
@import '01_variables/slick-slider';

// Slider Photos
@import '01_variables/slider-photos';

// Calendar
@import '01_variables/calendar';

// Signpost
@import '01_variables/signpost';

// Trailer Card
@import '01_variables/trailer-card';

// Visiting Card
@import '01_variables/visiting-card';

// Award Card
@import '01_variables/award-card';

// Horizontal Navigation
@import '01_variables/horizontal-navigation';

// Scroll to top
@import '01_variables/scroll-to-top';

// Signpost Tiles
@import '01_variables/signpost-tiles';

// Signpost Dataset
@import '01_variables/signpost-dataset';

// Signpost Columns
@import '01_variables/signpost-cols';

// Expanding List
@import '01_variables/expanding-list';

// Yearbook
@import '01_variables/yearbook';

// Job
@import '01_variables/job';

// Tips
@import '01_variables/tips';

// Z-indexes
@import '01_variables/z-indexes';

// Indicator
@import '01_variables/indicator';

// Pagination
@import '01_variables/pagination';

// Javascript export
/* stylelint-disable */
:export {
	gutterWidth: $grid-gutter-width;
	screenSM: map-get( $grid-breakpoints, 'sm' );
	screenMD: map-get( $grid-breakpoints, 'md' );
	screenLG: map-get( $grid-breakpoints, 'lg' );
	screenXL: map-get( $grid-breakpoints, 'xl' );
	primaryColor: $primary;
	secondaryColor: $secondary;
}
