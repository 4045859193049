/*
 -	Variables - Tables
 */


$table-cell-padding-x:        ( $spacer * .8 );
$table-cell-padding-y:        2px;
$table-row-hover-color:       $theme-gray-light-2;
$table-border-width:          0;
$table-border-color:          $theme-gray-light-3;
$table-accent-bg:             transparent;
$table-striped-order:         even;
$table-th-font-weight:        300;

$table-control-button-bg:     $theme-green-dark;
$table-control-button-icon:   $theme-gray-medium-3;
$table-control-button-size:   28px;
