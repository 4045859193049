/*
 -  Print
 -
 -  Default print styles from typography and general layout
 */


/* stylelint-disable selector-max-universal, declaration-no-important */
* {
	text-shadow: none !important;
	// Black prints faster: h5bp.com/s
	color: $theme-black !important;
	background: transparent !important;
	box-shadow: none !important;
	max-width: auto !important;
	height: auto !important;
	width: auto !important;
	font-size: 1.5rem;
}

a,
a:visited {
	text-decoration: underline;
	color: $theme-black !important;
}

a[href]:after {
	content: ' (' attr(href) ')';
}

abbr[title]:after {
	content: ' (' attr(title) ')';
}

// Don't show links for images, or javascript/internal links
a[href^='javascript:']:after,
a[href^='#']:after {
	content: '';
}

pre,
blockquote {
	border: 1px solid lighten($theme-black, 20%);
	page-break-inside: avoid;
}

thead {
	display: table-header-group; // h5bp.com/t
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
footer,
.slick-slide,
.signpost-tiles__item,
.signpost-articles__item,
tr,
img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
}

p,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h2,
.accordion__header,
h3 {
	page-break-after: avoid;
}

h1 {
	font-size: 2.2rem;
}

h2 {
	font-size: 2.1rem;
}

h3 {
	font-size: 1.9rem;
}

h4 {
	font-size: 1.8rem;
}

// Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
// Once fixed, we can just straight up remove this.
select {
	background: $theme-white !important;
}

// Bootstrap components
.navbar {
	display: none;
}

.table {
	td,
	th {
		background-color: $theme-white !important;
	}
}

.btn,
.dropup > .btn {
	> .caret {
		border-top-color: $theme-black !important;
	}
}

.table {
	border-collapse: collapse !important;
}

iframe {
	display: none !important;
}

.table-responsive-wrapper::after {
	display: none !important;
}

.table-sticky-col {
	th::after,
	td::after {
		display: none !important;
	}
}

.table-bordered {
	th,
	td {
		border: 1px solid darken($theme-white, 10%) !important;
	}
}

.mp-intro {
	display: block;
	padding: 0;
	height: unset;
	width: 100%;
	min-height: unset;

	.container,
	.row,
	.col {
		display: block;
		text-align: center;
	}

	&__content {
		background-color: $theme-gray-medium-3 !important;
	}

	img {
		display: inline-block;
		margin: 0 !important;
		max-height: 100px !important;
	}

	h1 {
		margin-bottom: 0;
		color: $theme-white !important;
	}
}

.mp-section {
	padding: ( $spacer * 3 ) 0 !important;
}

.header {
	height: ( $spacer * 3 );

	&__inner {
		text-align: center;

		.logo {
			background-color: $theme-green-light-3 !important;
			display: block;
			padding: $spacer;
			margin: 0 auto $spacer;

			&__image {
				display: inline-block;
			}
		}
	}

	&__user {
		display: none !important;
	}
}

.banner-wrapper,
.signpost-dataset-header__bottom,
.scroll-to-top {
	display: none !important;
}

.signpost-cols,
.signpost-articles {
	max-width: unset;
}

.signpost-articles {
	&__item-content {
		padding: 0;
	}

	&__image {
		display: none;
	}
}

.slick-dots,
.signpost-cols {
	display: none !important;
}

.signpost-articles,
.signpost-tiles,
.signpost-dataset-button,
.signpost-dataset {
	.btn {
		display: none !important;
	}
}

.signpost-dataset {
	display: block;

	.signpost-dataset__item {
		page-break-inside: avoid;
	}
}

.signpost-dataset__content {
	display: inline-block;
	width: 100%;
}

.signpost-dataset__keywords {
	display: block;

	a {
		page-break-inside: avoid;
	}
}

.carousel {
	max-width: 100% !important;

	* {
		overflow: visible !important;
		translate: none !important;
		text-align: left;
	}

	&__link-icon {
		.icon {
			display: none !important;
		}
	}
}

.slick-track,
.slick-slide {
	display: block !important;
	height: auto !important;
	width: 100% !important;
	transform: unset !important;

	* {
		display: block !important;
	}
}

.accordion {
	&__header {
		padding-left: 0;

		.icon {
			display: none;
		}
	}

	&__content {
		display: block;
	}

	&__content-separator {
		display: none !important;
	}
}


.footer {
	border-top: 2px solid $theme-gray-medium-3;
	margin-top: $spacer;

	&__top,
	.footer__link,
	.scroll-to-top--showed {
		display: none;
	}

	&__bar-copy {
		opacity: 1 !important;
	}
}

/* stylelint-enable selector-max-universal, declaration-no-important */
