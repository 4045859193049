/*
 -	Theme mixins
 -
 -	This file defines new mixins and extends standards mixins from Bootstrap.
 */

/*
 -	Font-size mixin
 -
 -	Mixin for rem font sizing with fallback, use it with only if you need browser support < IE11
 -	@param {Number} [size-value] - Size of text
 */

@mixin font-size($size-value: $font-size-base-px) {
	font-size: $size-value + px;
	font-size: ($size-value / 16) + rem;
}


/*
 -	Icon font
 -	@param {String} [icon] - Icon name
 */

@mixin icon-font($icon) {
	content: url('../assets/images/icons/#{$icon}.svg');
	font-family: 'icon-font' !important; /* stylelint-disable-line */
}


/*
 -  Disabled outline
 */

@mixin clear-outline() {
	outline: none;
	outline: 0;
}


/*
 -  Placeholder
 -
 -  Change color of font in placeholder for input
 -  @param {HEX Color} [color]
 */

@mixin custom-placeholder($color) {
	/* WebKit browsers */
	&::-webkit-input-placeholder {
		color: $color;
	}

	/* Mozilla Firefox 4 to 18 */
	&:-moz-placeholder {
		color: $color;
	}

	/* Mozilla Firefox 19+ */
	&::-moz-placeholder {
		color: $color;
	}

	/* Internet Explorer 10+ */
	&:-ms-input-placeholder {
		color: $color;
	}
}


/*
 -  Selection Style
 -
 -  Change appearence of highlighted text
 -  @param {HEX Color} [highlight-color]
 -  @param {HEX Color} [font-color]
 */

@mixin selection($highlight-color: $theme-black, $font-color: $theme-white) {
	::selection {
		color: $font-color;
		background-color: $highlight-color;
	}

	::-moz-selection {
		color: $font-color;
		background-color: $highlight-color;
	}
}


/*
 -	Reset parameters padding and margin
 -	@param {Number} [value=0] - Set padding and margin to all sides
 */

@mixin reset-pa-ma($value: 0) {
	margin: $value;
	padding: $value;
}


/*
 -	Micro-clearfix method
 */

@mixin after-clear() {
	&::after {
		content: '';
		display: block;
		height: 0;
		clear: both;
	}
}


/*
 -  Font-face declaration for custom typography
 -  Comment out src block for more source font files if support for older browsers is needed
 -  @param {String} [font-name] - Name of the font family
 -  @param {url} [file-name] - URL address for font files
 -  @param {Number} [weight=400] - Weight or boldness of the font
 -  @param {String} [style=normal] - Face of the font-family (normal, italic or oblique)
 */

@mixin font-face($font-type, $font-name, $file-name, $weight: 400, $style: normal, $version: random(100)) {
	@font-face {
		font-family: quote($font-name);
		font-style: $style;
		font-weight: $weight;
		@if $font-type == '.ttf' {
			src: url($file-name + $font-type)  format('truetype');
		} @else if $font-type == '.otf' {
			src: url($file-name + $font-type)  format('opentype');
		}
	}
}


/*
 -	Absolute positioning of element to the center
 -	@param {Boolean} [vertical=true] - Vertical positioning of the element
 -	@param {Boolean} [horizontal=true] - Horizontal positioning of the element
 */

@mixin absolute-center($vertical: true, $horizontal: true) {
	position: absolute;
	display: block;

	@if $vertical == true and $horizontal == false {
		top: 50%;
		transform: translateY(-50%);
	} @else if $horizontal == true and $vertical == false {
		left: 50%;
		transform: translateX(-50%);
	} @else if $horizontal == true and $vertical == true {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}


/*
 -	Center positioning of the block content
 -	@param {String} [mode=row] - Direction of block elements in flex box (row or column)
 -	@param {Boolean} [vertical=true] - Vertical positioning of the element
 -	@param {Boolean} [horizontal=true] - Horizontal positioning of the element
 */

@mixin center-content($mode: row, $horizontal: true, $vertical: true) {
	display: flex;
	flex-direction: $mode;

	@if $mode == row {
		@if $vertical == true and $horizontal == false {
			align-items: center;
		} @else if $horizontal == true and $vertical == false {
			justify-content: center;
		} @else if $horizontal == true and $vertical == true {
			justify-content: center;
			align-items: center;
		}
	} @else if $mode == column {
		@if $vertical == true and $horizontal == false {
			justify-content: center;
		} @else if $horizontal == true and $vertical == false {
			align-items: center;
		} @else if $horizontal == true and $vertical == true {
			justify-content: center;
			align-items: center;
		}
	}
}


/*
 -	Overflow ellipsis multiline
 -	@param {Number} [rows] - Number of rows
 */

@mixin overflow-ellipsis-multiline($rows: 3) {
	/* stylelint-disable */
	display: -webkit-box;
	/* stylelint-enable */
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $rows;
}


/*
 -	Disabled state
 */

@mixin disabled-state() {
	opacity: $disabled-opacity;
	cursor: not-allowed;
	pointer-events: none;
}


/*
 *	Placeholder
 */

@mixin placeholder($color) {
	/* WebKit browsers */
	&::-webkit-input-placeholder {
		color: $color;
	}

	/* Mozilla Firefox 4 to 18 */
	&:-moz-placeholder {
		color: $color;
	}

	/* Mozilla Firefox 19+ */
	&::-moz-placeholder {
		color: $color;
	}

	/* Internet Explorer 10+ */
	&:-ms-input-placeholder {
		color: $color;
	}
}


/*
 -	Navigation vertical
 */

@mixin navigation-vertical-heading() {
	position: relative;
	margin: 0;
	padding: ( $spacer * .5 ) ( $spacer * 2 ) ( $spacer * .5 ) ( $spacer * .8 );
	font-weight: $font-weight-normal;
	line-height: 1.2;
	cursor: pointer;
	background-color: $theme-white;

	// Media queries
	@include media-breakpoint-down(xs) {
		@include font-size(24);
		padding-top: ( $spacer * .7 );
		padding-bottom: ( $spacer * .7 );
	}

	&::after {
		// @include icon-font('arrow-right');
		content: '\E003';
		position: absolute;
		top: 50%;
		right: $spacer;
		margin-top: -.5rem;
		/* stylelint-disable-next-line */
		font-family: 'aopkicons' !important;
		font-size: $small-font-size;
		color: $signpost-arrow-color;

		// Media queries
		@include media-breakpoint-up(xl) {
			display: none;
		}
	}

	&--active {
		&::after {
			transform: rotate(90deg);
			color: $theme-orange;
		}
	}
}

@mixin navigation-vertical-link() {
	display: flex;
	align-items: center;
	min-height: ( $spacer * 2 );
	padding-top: ( $spacer * .2 );
	padding-right: ( $spacer / .75 );
	padding-bottom: ( $spacer * .2 );
	line-height: 1.4;
	text-decoration: none;
	color: $signpost-link-color;

	@include hover-focus {
		color: $theme-green;
		background: transparent linear-gradient(90deg, #b1b1b100 0%, #a8a8a833 24%, #94949433 50%, #99999933 79%, #b1b1b100 100%) 0% 0% no-repeat padding-box;
	}

	&::after {
		// Media queries
		@include media-breakpoint-up(xl) {
			content: '\E003';
			position: absolute;
			right: 0;
			/* stylelint-disable-next-line */
			font-family: 'aopkicons' !important;
			font-size: $small-font-size;
			color: $theme-gray-medium;
		}
	}
}

/*
 -	Overflow ellipsis singleline
 */

@mixin overflow-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


/*
 -	Table - gradient after (before) content
 */

@mixin table-after-gradient {
	content: '';
	position: absolute;
	top: 0;
	display: block;
	width: $grid-gutter-width;
	height: calc(100% + #{$border-width});
	pointer-events: none;
}

