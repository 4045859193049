/*
 -	Variables - Buttons
 */


$btn-font-family:             $font-family-base;
$btn-font-size-sm:            $small-font-size;
$btn-font-size:               $h5-font-size;
$btn-font-size-lg:            $lead-font-size;
$btn-font-weight:             $font-weight-base;
$btn-line-height:             normal;
$btn-letter-spacing:          $font-letter-spacing-base;

$btn-padding-y-sm:            11px;
$btn-padding-x-sm:            ( $spacer * .7 );
$btn-padding-y:               23px;
$btn-padding-x:               22px;
$btn-padding-y-lg:            ( $spacer * .5 );
$btn-padding-x-lg:            ( $spacer * .7 );


$btn-padding-s-y-sm:          ( $spacer * .4 );
$btn-padding-s-x-sm:          ( $spacer * .9 );
$btn-padding-s-y:             23px;
$btn-padding-s-x:             22px;
$btn-padding-s-y-lg:          ( $spacer * .75 );
$btn-padding-s-x-lg:          ( $spacer * .9 );


$btn-padding-x-icon-sm:       36px;
$btn-padding-x-icon:          50px;
$btn-padding-x-icon-lg:       66px;

$btn-border-width:            0;
$btn-border-radius:           5px;
$btn-transition:              none;

$btn-min-height-sm:           42px;

$btn-secondary-icon-size-sm:  25px;
$btn-secondary-icon-size:     35px;
$btn-secondary-icon-size-lg:  40px;

/*
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;


$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;
*/
