/*
 -	Variables - Typography
 */


$text-color:                      $theme-gray-dark;
$body-color:                      $text-color;

$disabled-opacity:                .4;
$disabled-color:                  $body-color;

$font-size-base:                  1rem;                                                  // 16
$font-letter-spacing-base:        0;
$font-size-base-px:               16;

$font-family-base:               'Franklin Gothic Book', arial, sans-serif;
$font-family-headings:           'Franklin Gothic Book', arial, sans-serif;

$font-weight-light:              300;
$font-weight-base:               400;
$font-weight-bolder:             700;

$line-height-base:               ( 20 / $font-size-base-px );

$h1-font-size:                   $font-size-base * ( 44 / $font-size-base-px );
$h1-font-line-height:            ( 52 / 44 );
$h2-font-size:                   $font-size-base * ( 34 / $font-size-base-px );
$h2-font-line-height:            ( 40 / 34 );
$h3-font-size:                   $font-size-base * ( 30 / $font-size-base-px );
$h3-font-line-height:            ( 36 / 30 );
$h4-font-size:                   $font-size-base * ( 24 / $font-size-base-px );
$h4-font-line-height:            ( 28 / 24 );
$h5-font-size:                   $font-size-base * ( 22 / $font-size-base-px );
$h5-font-line-height:            ( 26 / 22 );
$h6-font-size:                   $font-size-base * ( 16 / $font-size-base-px );
$h6-font-line-height:            ( 20 / 16 );

$headings-color:                 $theme-gray-dark;
$headings-font-weight:           400;

$list-line-height:               ( 28 / $font-size-base-px );


$lead-font-size:                 $font-size-base * ( 22 / $font-size-base-px );
$lead-line-height:               ( 28 / 22 );
$lead-font-weight:               400;

$small-font-size:                $font-size-base * ( 14 / $font-size-base-px );

$link-color:                     $theme-green;
$link-decoration:                underline;
$link-decoration-color:          $theme-green-light;

$link-hover-color:               darken($link-color, 15%);
$link-hover-decoration:          none;
$link-gradient-black-1:          rgba( $theme-white, 0);
$link-gradient-black-2:          rgba( $theme-white, .0509804);
$link-decoration-dotted:         solid underline $link-decoration-color;


$ordered-list-margin-bottom:     $spacer;

$unordered-list-margin-bottom:   $spacer;
$unordered-list-deco-size:              9px;
$unordered-list-deco-border-radius:     3px;


$image-margin-bottom:            $spacer;

$paragraph-margin-bottom:        $spacer;

$headings-margin-bottom:         $spacer;

$table-margin-bottom:            $spacer;
