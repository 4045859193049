/*
 -	Variables - General
 */


$body-bg:                         $theme-bg;
$border-radius:                   5px;
$border-style:                    solid;
$border-radius-50-pct:            50%;
$general-full-width:              1920px;
$general-signpost-item-radius:    6px;
$general-box-shadow-black:        0 0 ( $spacer / 2 ) #00000029;
$general-color-green-active:      darken($theme-green, 5%);
