/*
 -	Variables - Icons
 */


$icons: (
	'isop-alert',
	'isop-arrow-down',
	'isop-arrow-previous',
	'isop-arrow-left',
	'isop-arrow-right',
	'isop-arrow-up',
	'isop-arrow-url',
	'isop-biolog',
	'isop-document',
	'isop-download',
	'isop-envelope',
	'isop-facebook',
	'isop-gallery',
	'isop-hamburger',
	'isop-home',
	'isop-info',
	'isop-krajinotvorne-programy',
	'isop-link',
	'isop-lokalizace-spisu',
	'isop-mapomat',
	'isop-map',
	'isop-plus',
	'isop-pozemky',
	'isop-report-bug',
	'isop-search',
	'isop-twitter',
	'isop-user',
	'isop-youtube',
	'isop-export',
	'isop-sort',
	'isop-filter',
	'isop-filter-slash',
	'isop-pagination-arrow-first',
	'isop-pagination-arrow-last',
	'isop-pagination-arrow-next',
	'isop-pagination-arrow-prev',
	'isop-pagination-arrow-select',
	'isop-carousel-arrow-prev',
	'isop-carousel-arrow-next',
	'isop-help',
	'isop-sign-off',
	'ptaci-klesajici',
	'ptaci-kolisajici',
	'ptaci-nejisty',
	'ptaci-neznamy',
	'ptaci-stabilni',
	'ptaci-stoupajici',
	'arrow-left',
	'arrow-right',
	'arrow-down',
	'arrow-up',
	'arrow-thin-left',
	'arrow-thin-right',
	'arrow-thin-down',
	'arrow-thin-up',
	'calendar',
	'caret-left',
	'caret-right',
	'caret-down',
	'caret-up',
	'close',
	'check',
	'point',
	'search',
	'tree-leaf',
	'home',
	'domy-prirody',
	'chci-podat-stiznost',
	'chci-podat-zadost',
	'chci-pozadat-o-informace-nebo-data',
	'chci-se-stat-biologem',
	'chci-ziskat-dotaci',
	'jak-dokumentujeme-prirodu',
	'jak-chranime-prirodu-a-krajinu',
	'jak-pecujeme-o-prirodu-a-krajinu',
	'potrebuji-povoleni-nebo-stanovisko',
	'tipy-na-vylet',
	'rozmanitosti-prirody',
	'facebook',
	'instagram',
	'linkedin',
	'youtube',
	'chuze',
	'kolo',
	'plus',
	'minus',
	'gallery',
	'cancel',
	'picto-trend-kolisavy',
	'picto-trend-neznamy',
	'picto-trend-setrvaly',
	'picto-trend-zhorsujici',
	'picto-trend-zlepsujici'
);

$icon-isop-size:           20;
$icon-isop-size-px:        20px;
$icon-isop-size-big-value: 70;
