/*
 -	Variables - Colors
 */


$theme-white:                   #fff;                              // Pure white color
$theme-black:                   #000;                              // Pure black color
$theme-black-second:            #36322a;
$theme-brown:                   #874400;
$theme-gray-dark:               #36332a;
$theme-gray:                    #5c5c5c;
$theme-gray-second:             #6c757d;
$theme-gray-medium:             #b1b1b1;
$theme-gray-medium-1:           #a8a8a8;
$theme-gray-medium-2:           rgba(#e5e5e5, .3);
$theme-gray-medium-3:           #707070;
$theme-gray-medium-4:           #9e9e9e;
$theme-gray-medium-5:           #c5c5c5;
$theme-gray-medium-6:           #dddddd;
$theme-gray-light:              #efeeed;
$theme-gray-light-2:            #f2f8f6;
$theme-gray-light-3:            #e9ecef;

$theme-green-light:             #d9eae5;
$theme-green-light-1:           #99bdb1;
$theme-green-light-2:           #227e65;
$theme-green-light-3:           #258168;
$theme-green-light-4:           #15775c;
$theme-green:                   #006c4e;
$theme-green-medium:            #00563b;
$theme-green-dark:              #2ea886;
$theme-green-bright:            #94f204;
$theme-orange-lighter:          #f59b23;
$theme-orange:                  #ee8a25;
$theme-red:                     #da0800;
$theme-red-darker:              #cd011a;
$theme-yellow:                  #fee49f;
$theme-blue:                    #4078b8;




$theme-bg:                      $theme-white;
$primary:                       $theme-green;
$secondary:                     $theme-black;
$danger:                        $theme-red;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary':    $theme-green,
    'secondary':  $theme-orange,
  ),
  $theme-colors
);
