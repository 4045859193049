/*
 -	Variables - Layout
 */


$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 720px,
	xl: 1280px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1230px
);

$grid-gutter-width:               30px;
$spacer:                          30px;
