/*
 -	Variables - Navigation
 */


$navigation-mobile-toggle-width:                  30px;
$navigation-mobile-toggle-item-height:            5px;
$navigation-mobile-list-item-padding:             0 0 ( $spacer / 7.5 );
$navigation-mobile-border-bottom-xs:              1px solid rgba($theme-white, .2);
$navigation-language-switcher-size:               22px;
$navigation-menu-primary-item-padding-x-xl:       ( $spacer * .5 );
$navigation-submenu-height-xl:                    50px;
$navigation-submenu-toggler-size:                 42px;

$navigation-user-box-shadow:                      0 4px 8px rgba($theme-black, .16);
$navigation-user-name-border-bottom-width:        1px;
$navigation-user-name-padding-x:                  18px;
$navigation-user-item-padding-y:                  25px;
$navigation-user-item-padding-x:                  20px;
$navigation-user-item-icon-color:                 $theme-green-light-4;