/*
 -  Theme icons
 -
 -  This file contains all used custom icons.
 */


@each $icon in $icons {
	.icon--#{$icon}::before {
		// fixme: no validate syntax!
		icon-font: url('../assets/images/icons/#{$icon}.svg');
	}

	// .btn--simple-icon-#{$icon}::after {
	// 	@include icon-font('#{$icon}');
	// }
}
