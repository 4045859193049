/*
 -	Variables - Header
 */


$header-height-xs:                                110px;
$header-height-xl:                                293px;
$header-top-bar-height:                           50px;
$header-logo-width:                               140px;
$header-top-bar-menu-min-width:                   450px;
$header-search-bar-width-xl:                      415px;
$header-search-bar-height-xl:                     50px;
$header-inner-border-width:                       2px;
$header-inner-border-style:                       solid;
$header-user-icon-size:                           50px;
$header-user-icon-size-min:                       42px;
