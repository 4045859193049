/*
 -	Variables - Breadcrumb
 */


$breadcrumb-padding-y:              ( $spacer / 10 );
$breadcrumb-padding-x:              ( $spacer / 2 );

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     rgba($theme-white, .4);
$breadcrumb-divider-color:          $theme-orange;
$breadcrumb-active-color:           $theme-white;

$breadcrumb-border-radius:          0;