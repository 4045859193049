/*
 -	Variables - Horizontal navigation
 */



$horizontal-navigation-height-xs:        60px;
$indicator-size:                         26px;
$indicator-color-red:                    $theme-red-darker;
$indicator-color-green:                  $theme-green-bright;
$indicator-color-orange:                 $theme-orange-lighter;
